<template>
  <div>
    <div class="title">
      <p>
        <img :src="data.img" alt="icon" /> {{ data.title }}
      </p>

      <p v-if="data.more">
        <router-link :to="{ name: 'examinfosummery' }">
          <van-icon name="arrow" />
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import {  Icon} from "vant";
export default {
    props: {
      data: Object,
      required: true
    },
    components: {
    [Icon.name]: Icon,
  },
};
</script>

<style lang="less" scoped>
.title {
  width: 100%;
  display: flex;
  padding: 11px 6px 4px 11px;
  justify-content: space-between;
  p {
    font-size: 20px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #141414;
    display: flex;
    align-items: center;
    img {
      width: 26px;
      height: 26px;
      margin-right: 6px;
    }
  }
}
</style>
