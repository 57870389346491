<template>
  <div class="examinfosummery">
    <hometitle :data="hometitles[0]"></hometitle>
    <!-- <div class="lessonclassfiy">
      <p>全部</p>
      <p v-for="(item, id) in lessonstag" :key="id">
        {{ item.cataName }}
      </p>
    </div> -->

    <div class="examtags">
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="value1"
          :options="eaxmtag"
          @change="getexamtag"
        />
        <van-dropdown-item
          v-model="value2"
          :options="schools"
          @change="getexamprovice"
        />
      </van-dropdown-menu>
    </div>

    <div
      class="examinfom"
      v-for="(item, index) in examinfolist"
      :key="index"
      @click="todetail(item.id)"
    >
      <div class="item">
        <p class="itemtitle">{{ item.title }}</p>
        <p class="itemtime">{{ item.signSTime }}~{{ item.signETime }}</p>
        <p class="itemposition">{{ item.cataName }}·{{ item.province }}</p>
      </div>
    </div>
    <van-pagination
      v-model="currentPage"
      :total-items="totalpage"
      :items-per-page="10"
      @change="pagechage"
    />
  </div>
</template>

<script>
import axios from "axios";
import hometitle from "../components/hometitle.vue";
import { hometitles } from "../../tempdata/home/homelessons";
import { DropdownMenu, DropdownItem, Notify, Pagination } from "vant";
import { getschoolinfo, getexaminfo, queryexaminfo } from "../../api/index";
export default {
  data() {
    return {
      lessonstag: [],
      examinfolist: [],
      hometitles,

      value1: -1,
      value2: -1,
      eaxmtag: [{ text: "考试类型", value: -1 }],
      schools: [{ text: "省份", value: -1 }],
      theschools: "",
      thexamtag: "",

      currentPage: 1,
      totalpage: 0,
    };
  },
  components: {
    hometitle,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Pagination.name]: Pagination,
    [Notify.name]: Notify,
  },
  created() {
    this.getexaminfolist();
    // this.examinfolist=queryexaminfo(null,null)
    getschoolinfo();
    getexaminfo();
    this.hometitles[0].more = 0;
    // console.log(hometitles[0]);
  },
  mounted() {
    setTimeout(() => {
      this.setoption();
    }, 1000);
  },
  methods: {
    getlessontag() {
      const url = "https://das.boxuanedu.cn/dd/rest/courseCata/export";
      axios
        .post(url)
        .then((res) => {
          this.lessonstag = res.data.data.list;
        })
        .catch((err) => console.log(err));
    },
    async getexaminfolist() {
      const end = await queryexaminfo(null, null);
      this.examinfolist = end.data.data.list;
      this.totalpage = end.data.data.count;
      // console.log(await queryexaminfo(null,null));
      // this.examinfolist = queryexaminfo(null,null)
    },
    todetail(id) {
      this.$router.push({ name: "examinfodetail", query: { id } });
    },
    /* 获取下拉菜单数据 */
    async getexamtag(value) {
      this.thexamtag = value;
      const cataId = this.thexamtag;
      const province = this.theschools;

      const end = await queryexaminfo(cataId, province);

      const data = end.data.data.list;

      this.examinfolist = data;
      this.totalpage = end.data.data.count;
      if (this.examinfolist.length == 0) {
        Notify({
          type: "success",
          message: `该类别信息正在准备中...敬请期待`,
          duration: 1000,
        });
      }
    },
    async getexamprovice(value) {
      this.theschools = value;
      const province = this.theschools;
      const cataId = this.thexamtag;

      const end = await queryexaminfo(cataId, province);
      const data = end.data.data.list;
      this.totalpage = end.data.data.count;
      this.examinfolist = data;
      
      if (this.examinfolist.length == 0) {
        Notify({
          type: "success",
          message: `该类别信息正在准备中...敬请期待`,
          duration: 1000,
        });
      }
    },
    /* 考试信息选项数据--省份 */
    setoption() {
      const schoolslist = JSON.parse(sessionStorage.getItem("schools"));
      const exmataglist = JSON.parse(sessionStorage.getItem("exmatag"));

      for (const key in schoolslist) {
        this.schools.push({
          text: schoolslist[key].province,
          value: schoolslist[key].province,
        });
      }

      for (const key in exmataglist) {
        this.eaxmtag.push({
          text: exmataglist[key].cataName,
          value: exmataglist[key].id,
        });
      }
    },

    /* 下一页 */
    async pagechage() {
      console.log(this.currentPage);
      const end = await queryexaminfo(
        this.thexamtag,
        this.theschools,
        this.currentPage
      );
      this.examinfolist = end.data.data.list;
      this.totalpage = end.data.data.count;
    },
    /**/
  },
};
</script>

<style lang="less" scoped>
.examinfosummery {
  .lessonclassfiy {
    width: 100%;
    overflow: auto;
    display: flex;
    padding: 10px;
    margin-bottom: 12px;
    background: #fff;
    p {
      flex: none;
      font-size: 16px;
      color: #8a9095;
      margin-right: 12px;
      padding: 0 0.13333rem;
    }
  }
  .examinfom {
    padding: 2px 10px 0px 10px;
    margin-bottom: 12px;
    margin-top: 12px;
    .item {
      height: 108px;
      background: #dcebfd;
      border: 1px solid #dcebfd;
      border-radius: 12px;
      padding: 10px;
      .itemtitle {
        color: #020b10;
        font-weight: 500;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .itemtime {
        margin-bottom: 32px;
        font-weight: 400;
        font-size: 12px;
        color: #0935d6;
      }
      .itemposition {
        font-size: 10px;
        color: #b1b5b9;
      }
    }
  }

  .examtags {
    width: 100%;
    padding: 12px 10px 0 10px;
    /deep/.van-dropdown-menu__bar {
      position: relative;
      display: flex;
      height: 8.8vw;
      background-color: #f5f5f5;
      box-shadow: none;
      border-radius: 2.133333vw;
    }
  }
}
</style>
