/* 优选课程配置 */
const preferlessons = [];

/* 热门课程配置 */
const hotlessons = [];

/* 限时优惠课程配置 */
const limitlessons = [];

/* 首页标题设置 */
const hometitles = [
  {
    title: "考试信息",
    img: "https://oss.oxke.net/boxuanedu-m/home/icon/icon1",
    more: 1,
  },
  {
    title: "试听课程",
    img: "https://oss.oxke.net/boxuanedu-m/home/icon/icon2",
    more: 0,
  },
  {
    title: "优选课程",
    img: "https://oss.oxke.net/boxuanedu-m/home/icon/icon3",
    more: 0,
  },
  {
    title: "热门课程",
    img: "https://oss.oxke.net/boxuanedu-m/home/icon/icon3",
    more: 0,
  },
  {
    title: "师资介绍",
    img: "https://oss.oxke.net/boxuanedu-m/home/icon/icon4",
    more: 0,
  },
  {
    title: "公司介绍",
    img: "https://oss.oxke.net/boxuanedu-m/home/icon/icon3",
    more: 0,
  },
  {
    title: "限时优惠",
    img: "https://oss.oxke.net/boxuanedu-m/home/icon/icon3",
    more: 0,
  },
];

export { preferlessons, hotlessons, hometitles, limitlessons };
